<template>
    <div class="sandbox-badge" v-if="moduleFree == '1' && profRef == dataReceiver">
        <small class="m-0 d-block">Deberán</small>
        <small class="m-0 d-block">contratar el módulo</small>
    </div>
    <div class="w-100 text-center container-video-lesson d-flex justify-content-center align-items-center" v-if="!showVideo && moduleFree == '0'"> 
        <img src="@/assets/imgs/spinner.webp" class="img-fluid waiting-img-lesson" />
    </div>
    <div class="w-100 text-center container-lesson full" id="material" v-if="moduleFree == '1' && profRef != dataReceiver">  
        <div style="position: relative;">  
            <div class="container-question initial-quiz in d-flex justify-content-center align-items-center" id="initial">
                <div class="h-100">
                    <div>
                        <div>
                            <div class="row mx-auto">
                                <div class=" col-12 details">
                                    <div>
                                        <h5 class="m-0 mt-4">Taller:</h5>
                                        <h5 class="text-uppercase">{{publishTitle}}</h5>  
                                    </div>
                                </div>  
                            </div>   
                            <div v-if="moduleFree == '1'" class="">
                                <payment-cart v-if="profRef != ''" :profRef="profRef" :couserRef="couserRef" :publishTitle="publishTitle" :moduleName="moduleName" :lessonTitle="titleClass" :moduleId="moduleId" :realPriceInter="realPriceInter" :realPriceLocal="realPriceLocal"></payment-cart>
                            </div>      
                        </div>
                    </div>   
                    
                </div>
            </div> 
        </div>
    </div>
   
    <div class="w-100 text-center container-video-lesson" v-show="(showVideo && moduleFree == '0') || (showVideo && profRef == dataReceiver)" id="material">      
        <vue-vimeo-player v-if="videoFile" ref="player" :video-id="videoFile" :options='optionsVimeo' @playing="isPlaying()" @ended="isFinish()" @ready="showVideo = true"/>
    </div>

    <div class="hide-horizontal" v-if="moduleFree == '0' || profRef == dataReceiver">
        <div class="options-lesson">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="d-block d-lg-none mt-2">
                            <div class="d-flex justify-content-start align-items-center">
                                <img :src="profImg" class="img-user" />
                                <p class="m-0">{{ profName }}</p>
                            </div>
                            <p class="m-0 mt-2">{{moduleName}}</p>
                            <h4 class="titleLesson mb-4 mt-0">{{ titleClass }}</h4>
                        </div>  
                        <div class="d-none d-lg-flex justify-content-between align-items-center mt-4 mb-4">
                            <div>
                                <p class="m-0">{{moduleName}}</p>
                                <h3 class="titleLesson mb-0 mt-0">{{ titleClass }}</h3>
                            </div>
                            <div class="d-flex justify-content-start align-items-center">
                                <img :src="profImg" class="img-user" />
                                <p class="m-0">{{ profName }}</p>
                            </div>
                        </div>   
                        <div class="description-lesson mb-4" v-html="descriptionPrivate"></div>
                        <div class="mb-5 text-center">
                            <img src="@/assets/imgs/logo-white.png" class="mx-auto mt-5 mb-5" style="width: 152px; filter: brightness(0.5);" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
import axios from "axios";
import { Toast } from 'bootstrap';
import paymentCart from '@/components/course-show/payment-cart.vue' 
export default {   
    name: "video-module",
    props: ['moduleId', 'lessonId', 'couserRef', 'profName', 'profImg', 'moduleName', 'localZone', 'publishTitle', 'bookingId'], 
    data(){
        return{
            errorText: '',
            toastColor: '',
            toastBg: '',
            toastNumber: 0,
            dataReceiver: '',
            videoFile: '',
            optionsVimeo: { //opciones de vimeo
				muted: false,
                autoplay: false,
                controls: true,
                color: '4586e6',       
			},
            showVideo: false,
            hasvideo: false,
            titleClass: '',
            typeClass: 1,
            duration: 0,
            descriptionPrivate: '',
            moduleFree: 0,
            profRef: '',  
            realPriceLocal: 0,
            realPriceInter: 0,
        }
    },

    mounted: function () {
        this.dataReceiver = localStorage.getItem('token');
        this.findLessonData()
    },
    components: {
        paymentCart,
        vueVimeoPlayer
    },
    methods:{
        isPlaying(){
        },
        isFinish(){
            this.$parent.markReady(this.lessonId, this.moduleId)
        },
        async findLessonData(){
            var payload = {
                action: 'find-lesson-data-workshop',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.dataReceiver,
                couserRef: this.couserRef,
                moduleId: this.moduleId,
                lessonId: this.lessonId,
                bookingId: this.bookingId
            }
            await axios.post('/index.php', payload)
            .then(result => {
                this.waiting = false;
                this.titleClass = result.data.titleClass;
                this.typeClass = result.data.typeClass;
                this.duration = result.data.duration;       
                this.descriptionPrivate = result.data.descriptionPrivate;
                this.videoFile = result.data.video; 
                this.realPriceLocal = result.data.realPriceLocal;
                this.realPriceInter = result.data.realPriceInter;
                this.profRef = result.data.userId;
                this.moduleFree = result.data.moduleFree;
                if(this.moduleFree == '1'){
                    this.$parent.hideShowBtnMessage(true);
                }else{
                    this.$parent.hideShowBtnMessage(false);
                } 
                if(this.moduleFree == '0'){
                    if(this.videoFile == ''){
                        this.hasvideo = false;
                    }else{
                        this.hasvideo = true;             
                    }
                }
            });
        },    
    }
}
</script>

