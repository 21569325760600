import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGoogleApi from 'vue-google-api'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.css'
import DropZone from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import vueVimeoPlayer from 'vue-vimeo-player';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
// create the 'options' object
const clientConfig = {
    apiKey: 'AIzaSyBz8fR0-0JxOwzuLcSmtpQR-OgM8llyJQE',
    clientId: '776599169387-n7tbj84v3kq6p45bk88can47bn1lutnh.apps.googleusercontent.com',
    discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
    scope: 'https://www.googleapis.com/auth/calendar',
    // works only with `ux_mode: "prompt"`
    //refreshToken: true,
  }

if(window.location.host == 'h.animasana.net' || window.location.host == 'animasana.net'){
  axios.defaults.baseURL = 'https://api.animasana.net'
}else if(window.location.host == 'api-qa.animasana.net'){
  axios.defaults.baseURL = 'https://api-qa.animasana.net'
}else{
  axios.defaults.baseURL = 'https://api-qa.animasana.net'
}

createApp(App).use(store, VueAxios, axios, VueGoogleApi, clientConfig, DropZone, vueVimeoPlayer, Multiselect).component('EasyDataTable', Vue3EasyDataTable).use(router).mount('#app')


