<template>
    <div class="sandbox-badge" v-if="moduleFree == '1' && profRef == dataReceiver">
        <small class="m-0 d-block">Deberán</small>
        <small class="m-0 d-block">contratar el módulo</small>
    </div>
    <div class="w-100 text-center container-lesson full" id="material">  
        <div style="position: relative;">  
            <div class="container-question initial-quiz in d-flex justify-content-center align-items-center" id="initial">
                <div class="h-100">
                    <div  v-if="liveDate.length > 0">
                        <div v-for="d in liveDate" :key="d.id">
                            <div class="row mx-auto">
                                <div class=" col-12 details">
                                    <div>
                                        <h5 class="m-0 mt-4">Taller:</h5>
                                        <h5 class="text-uppercase">{{d.publishTitle}}</h5>  
                                    </div>
                                </div>  
                            </div>   
                            <div v-if="moduleFree == '1' && profRef != dataReceiver" class="">
                                <payment-cart v-if="profRef != ''" :profRef="profRef" :couserRef="couserRef" :publishTitle="d.publishTitle" :moduleName="moduleName" :lessonTitle="d.lessonTitle" :moduleId="moduleId" :realPriceInter="realPriceInter" :realPriceLocal="realPriceLocal"></payment-cart>
                            </div>      
                            <!-- balance pagar solo esta consulta-->
                            <div class="container menu-options mt-3" v-if="moduleFree == '0' || profRef == dataReceiver">
                                <div class="row mx-auto">
                                    <div class="col-12 mb-0 details"> 
                                        <p class="m-0">{{moduleName}}</p>
                                        <h5 class="therapy-name m-0">{{ d.lessonTitle }}</h5> 
                                    </div>
                                    <div class="pb-3 mt-0 d-grid gap-2 col-12 mx-auto">
                                        <div class="" v-if="d.typeClass == 7">
                                            <div style="">
                                                <p class="text-center mt-2">Deberás enseñar el código QR para poder ingresar al evento.</p>
                                                <div class="mt-2 mb-4">
                                                    <img :src="qrUrl + d.imgQr" class="img-fluid m-0 img-qr" style="max-width: 200px">
                                                </div> 
                                            </div>  
                                        </div>  
                                        <h6 v-if="d.typeClass == '6'" class="mt-3"><b><i class="las la-calendar text-primary"></i> Vivo Online {{d.liveDate}} </b></h6>
                                        <h6 v-if="d.typeClass == '7'" class=""><b><i class="las la-calendar text-primary"></i> Vivo Presencial {{d.liveDate}} </b></h6> 
                                        <p class="m-0">De: {{d.startDate}} Hasta: {{d.endDate}} </p>
                                        <div class="" v-if="d.typeClass == 6">           
                                            <button v-if="d.canEnter" class="btn-primary w-100" @click="goAction('course-room', d.id)">Ingresar al evento</button>
                                            <button v-if="!d.canEnter" class="btn-primary w-100" disabled>Ingresar al evento</button>    
                                        </div>
                                        <div>
                                            <p v-if="d.typeClass == '7'" class="m-0">Lugar del evento {{d.address}} </p>
                                        </div>      
                                        <small class="fst-italic">* Horarios del país del profesional</small>
                                        <div v-if="d.typeClass == '6'">
                                            <small class="fst-italic">* Podras ingresar al vivo el día del evento.</small>
                                        </div> 
                                        <button class="btn btn-secondary btn-sm" @click="isFinish()">Marcar como realizado</button>
                                        <div class="mb-5"> 
                                            <img src="@/assets/imgs/logo-white.png" class="mx-auto mt-5 mb-5" style="width: 152px; filter: brightness(0.5);" />
                                        </div>       
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>   
                    <div class="text-center mt-5" v-if="liveDate.length == 0">
                        <!--<h4>Aquí se mostrará información del vivo al consultante.</h4>-->
                    </div>
                </div>
            </div> 
        </div>
    </div>
   
</template>
<script>
import axios from "axios";
import { Toast } from 'bootstrap';
import paymentCart from '@/components/course-show/payment-cart.vue' 
export default {   
    name: "live-module",
    props: ['moduleId', 'lessonId', 'couserRef', 'profName', 'profImg', 'moduleName', 'localZone', 'publishTitle', 'bookingId'], 
    data(){
        return{
            errorText: '',
            toastColor: '',
            toastBg: '',
            toastNumber: 0,
            dataReceiver: '',
            liveDate: [],
            liveDate: '',
            liveOptions: false,
            moduleFree: 0,
            profRef: '',  
            realPriceLocal: 0,
            realPriceInter: 0,        
        }
    },
    components: {
        paymentCart,
    },  
    mounted: function () {
        this.dataReceiver = localStorage.getItem('token');
        this.findLessonData()
    },
    methods:{    
        isFinish(){
            this.$parent.markReady(this.lessonId, this.moduleId)
        },
        async findLessonData(){
            var payload = {
                action: 'find-lesson-data-workshop',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.dataReceiver,
                couserRef: this.couserRef,
                moduleId: this.moduleId,
                lessonId: this.lessonId,
                bookingId: this.bookingId
            }
            await axios.post('/index.php', payload)
            .then(result => { 
                this.realPriceLocal = result.data.realPriceLocal;
                this.realPriceInter = result.data.realPriceInter;
                this.profRef = result.data.userId;
                this.moduleFree = result.data.moduleFree;  
                if(this.moduleFree == '1'){
                    this.$parent.hideShowBtnMessage(true);
                }else{
                    this.$parent.hideShowBtnMessage(false);
                } 
                this.findLiveDate();
            });
        }, 
        async findLiveDate(){   
            if(window.location.host == 'h.animasana.net' || window.location.host == 'animasana.net'){
                this.qrUrl = 'https://api.animasana.net/';
            }else{
                this.qrUrl = 'https://api-qa.animasana.net/';
            }
            this.liveDate = [];
            var mode = 2;
            if(this.profRef == this.dataReceiver){
                mode = 1;
            }
            var payload = {
                action: 'find-live-date',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.dataReceiver, 
                mode: mode,  
                timezone: this.localZone,
                lessonId: this.lessonId
            }
            await axios.post('/index.php', payload).then(result => { 
                this.liveDate = result.data;
                this.liveOptions = 'on'; 
            })
            .catch(error => {
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }   
                return;
            })
        },
    }
}
</script>
<style>
.module-lock-icon{
    padding: 11px;
    width: 60px;
    height: 60px;
    background: #fdc31430;
    border: 1px solid;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 1px;
    font-size: 35px;
}
.cont-course-pay{
    position: fixed;
    z-index: 10000000;
    top: 0;
    left: 0;
    width: 100%;
}
</style>