<template>
<div class="overflow-auto text-dark">
    <div id="cart-form" class="text-start light">
        <div class="container justify-content-md-center pt-1 pb-3"> 
            <section class="mt-2 mb-2" v-show="!hidePrice">
                <div class="">
                    <div class="">     
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="text-center hide-element subtotal-container" v-if="!noItems">
                                <h3 class="title-total">Valor del módulo:</h3>
                                <div class="price">
                                    <h2 v-if="this.payRealPriceNoFormat != '0'" id="subtotal"><span class="currency totalcurrency text-primary"></span><span>{{ userCurrency }} {{ userPrice }}</span></h2>
                                    <h2 v-if="this.payRealPriceNoFormat == '0'" id="subtotal"><span class="currency totalcurrency text-primary"></span><span>GRATIS</span></h2>
                                    <h2></h2>
                                </div>  
                            </div>
                            <p class="text-center small fst-italic" v-if="alertcurrency && showLocalConvertedPrice != ''">Valor aproximado en tu moneda <b>{{showLocalCurrencyPrice}} {{showLocalConvertedPrice}}</b></p>
                        </div>        
                    </div>
                </div>
            </section>         
        </div>
    </div>
</div>
<footer class="footer mt-auto footer-cart" v-if="!hidePrice && !noItems">
  <div class="container"> 
    <div class="">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="mb-3 mt-3 gap-2 col-12 mx-auto">
          <button class="btn btn-primary mx-auto" @click="confirmPay()" :disabled="disabled == true">{{ btnPayText }}</button>
        </div>
      </div>
    </div>
  </div>
</footer>
<div class="position-fixed bottom-0 start-0 end-0 p-0 pb-3 mx-auto" style="z-index: 11000; width: fit-content;">
    <div id="toastcontainer" class="w-25">
    </div>
</div>
   
    <div id="publishTherapy">
    <div :class="classOverlayturns"></div>
        <div :class="classturns">
            <div class="container">
                <div class="row"> 
                    <div class="col-md-6">
                        <div class="calendar text-center">
                            <h4 class="fw-light section-title text-center">Metodos de pago</h4>
                            <i class="las la-bullhorn mt-5 text-primary" style="font-size: 48px"></i>
                            <h6 class="mt-5 fw-light text-center small fst-italic">Dependiendo de las opciones de pago seleccionadas por el Profesional podrás abonar con tarjeta de credito o debito ahora mismo o pagar luego en efectivo o transferencia bancaria.</h6>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <div class="w-100">
                                <div class="text-center">   
                                    <h4 class="fw-light">Opciones de Pago:</h4>
                                </div>
                                <div class="mb-3 d-grid gap-2 col-12 mx-auto mt-3">
                                    <button v-if="disabledPayCard" class="btn btn-primary" @click="openPasarela()">Pagar con tarjeta de credito/debito</button> 
                                    <button class="btn btn-secondary" :disabled="disabled == true" @click="payCashTransfer('1')">Pagar con transferencia</button> 
                                    <button v-if="disabledCash" class="btn btn-secondary" :disabled="disabled == true" @click="payCashTransfer('2')">Pagar en efectivo</button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div :class="loadingcart">
        <div>
            <img src="@/assets/imgs/spinner-white.webp" class="img-fluid w-50" />
        </div>
    </div>

    <div class="payment-container" id="testPay" v-show="pay">
        <button id="closePaymentPopUp" class="btn btn-outline-light btn-close-popup" @click="pay=false; hidecontent = false"><i class="las la-times"></i></button>
        <iframe  :src="buttonPasarela"></iframe>
    </div>
</template>
<script>
import axios from "axios";
import { Alert, Toast } from 'bootstrap'
let url;
export default {   
    name: "questions",
    data(){
        return{
            errorText: '',
            toastColor: '',
            toastBg: '',
            toastNumber: 0,
            noItems: false,
            title: '',
            price: '',
            originalDate: '', //fecha original sin  formatear
            date: '', //fecha que se muestra al profesional formateada segun pais
            hour: '', //hora del usuario
            hourSelected: '', //hora del profesional
            userIp: '',
            duration: '',
            modality: '',
            profetional: '',
            imgProf: '',
            mode: '',
            ref: '',
            therapy: '',
            payCash: '',
            payCard: '',
            payAfter: '',
            transferCash: '',
            fistPrice: 0,
            promoPrice: 0,
            realPriceLocal: 0, //precio local normal
            realPriceInter: 0, //precio internacional normal
            firstPriceLocal: 0, //precio local primera consulta
            firstPriceInter: 0, //precio internacional primera consulta
            promoPriceLocal: 0, //precio local promocion
            promoPriceInter: 0, //precio internacional promocion
            userCurrency: '',
            userPrice: '',
            userOldPrice: '',
            disabled: 'false',
            therapyId: '',
            dataReceiver: '',
            payRealPrice: 0, // para mostrar el valor real a pagar se formatea para el usuario
            payRealPriceNoFormat: 0, // para enviar a la pasarela, no se formatea
            alertcurrency: false,
            flagCurrency: false,
            pasarela: 0,
            pasarelaId: 0,
            pasarelaCurrency: '',
            secretKey: '',
            publicKey: '',
            pay: false,
            hidecontent: false,
            classOverlayturns: 'overlayturns',
            classturns: 'turns',
            modeId: 0,
            payCash: 0,
            payCard: 0,
            hidePrice: false,
            giftId: '',
            errorCoupons: '',
            datestring: '',
            payCashSelected: 0,
            totalAmmountOldNoFormat: 0,
            isPaid: 0,
            loadingcart: 'loading-cart',
            buttonPasarela: '',
            showLocalConvertedPrice: '',
            showLocalCurrencyPrice: '',
            disabledPayCard: true,
            disabledCash: true,
            fistBuyBool: true,
            tempBook: '',
            btnPayText: 'Contratar',
            qtySessions: 0,
            limitTime: false,
            limitTimeDays: 0,
            moduleId: 0,
            platformVideoCall: '', 
            linkVideoCall: '', 
            passVideoCall: '', 
            roomIdLink: ''
        }
    },
    mounted: function () { 
        if(window.location.host == 'h.animasana.net' || window.location.host == 'animasana.net'){
            url = 'https://api.animasana.net'
        }else if(window.location.host == 'api-qa.animasana.net'){
            url = 'https://api-qa.animasana.net' 
        }else{
            url = 'https://api-qa.animasana.net'
        } 
        
        if(this.$route.params.ref){ 
            if(this.$route.params.idPay != ''){
                this.payId = this.$route.params.idPay;
                this.tempBook = this.$route.params.tempBookId;
            }
            
            this.loadCart(); 
        }else{     
            if(!localStorage.getItem('token') && localStorage.getItem('cart')){    
                this.loadCart(); 
            }else if(localStorage.getItem('token') && localStorage.getItem('cart')){
                var e = JSON.parse(localStorage.getItem('cart'));
                var data = JSON.parse(localStorage.getItem('userData'));
                localStorage.setItem('userCountryId', data.userCountry);
                if(e.userCountry != data.userCountry){
                    alert('Debido a tu origen debemos cambiarte de moneda. Vuelve a seleccionar el turno.');
                    localStorage.removeItem('cart');
                    this.$router.back()
                    return;
                }
                this.fistBuyBool = e.firstBuyBook;
                this.loadCart();       
            }else{
                this.errorText = 'El tiempo para realizar la reserva expiro.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }	
                return;
            }
        }
    },
    methods:{
        
        async findUserCurrency(){
            var payload = {
                action: 'find-currency',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                userCountry: this.userCountry     
            }
            await axios.post('/index.php', payload)
            .then(result => {            
                this.showLocalCurrencyPrice = result.data[0].name;;
                localStorage.setItem('currencyCode', this.showLocalCurrencyPrice);   
                this.convertPrice(this.payRealPriceNoFormat, 'none');
            })
            .catch(error => {
                    this.loading = false;
                    this.disabled = false;	
                    return;
                })
        },
        async convertPrice(val, val3){
            var payload = {
                action: 'convert-price',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                userCountryId: this.userCountry,
                userCurrency: this.showLocalCurrencyPrice,
                profCurrency: this.userCurrency,
                onlyFormat: val3,
                price: val
            }
            
            await axios.post('/index.php', payload).then(result => {    
                this.showLocalConvertedPrice = result.data.price;     
            })         
        },
        payCashTransfer(val){
            this.transferCash = val;
            this.disabled = true;
            this.payCashSelected = 1;
            this.isPaid = 0;
            this.payId = 'cash';
            localStorage.setItem('cart', JSON.stringify({ref: this.ref,title: this.title, type: this.type, profetional: this.profetional, originaHour: this.hourSelected, userHour:  this.hour, dateSelected: this.originalDate, img: this.imgProf, duration: this.duration, fistPrice: this.fistPrice, promoPrice: this.promoPrice, userCurrency: this.userCurrency, userPrice: this.userPrice, userOldPrice: this.userOldPrice, userIp: this.userIp, userCountry: this.userCountry, profCountry: this.profCountry, realPriceLocal: this.realPriceLocal, realPriceInter: this.realPriceInter, firstPriceLocal: this.firstPriceLocal, firstPriceInter: this.firstPriceInter, promoPriceLocal: this.promoPriceLocal, promoPriceInter: this.promoPriceInter, therapyId: this.therapyId, profRef: this.dataReceiver, payRealPrice: this.payRealPrice, totalAmmountOldNoFormat: this.totalAmmountOldNoFormat, flagCurrency: this.alertcurrency, payCash: this.payCash, payCard: this.payCard, duration: this.duration, modeId: this.modeId, date: this.datestring, coupon: this.coupon, transferCash: this.transferCash, firstBuyBook: this.fistBuyBool, qtySessions: this.qtySessions, limitTime: this.limitTime,  limitTimeDays: this.limitTimeDays, hidePrice: this.hidePrice, moduleId: this.moduleId, giftId: this.giftId, platformVideoCall: this.platformVideoCall, linkVideoCall: this.linkVideoCall, passVideoCall: this.passVideoCall, roomIdLink: this.roomIdLink}));
            this.saveBooking();
        },
        payFree(){
            this.payCashSelected = 1;
            this.isPaid = 0;
            this.payId = 'cash';
            localStorage.setItem('cart', JSON.stringify({ref: this.ref,title: this.title, type: this.type, profetional: this.profetional, originaHour: this.hourSelected, userHour:  this.hour, dateSelected: this.originalDate, img: this.imgProf, duration: this.duration, fistPrice: this.fistPrice, promoPrice: this.promoPrice, userCurrency: this.userCurrency, userPrice: this.userPrice, userOldPrice: this.userOldPrice, userIp: this.userIp, userCountry: this.userCountry, profCountry: this.profCountry, realPriceLocal: this.realPriceLocal, realPriceInter: this.realPriceInter, firstPriceLocal: this.firstPriceLocal, firstPriceInter: this.firstPriceInter, promoPriceLocal: this.promoPriceLocal, promoPriceInter: this.promoPriceInter, therapyId: this.therapyId, profRef: this.dataReceiver, payRealPrice: this.payRealPrice, totalAmmountOldNoFormat: this.totalAmmountOldNoFormat, flagCurrency: this.alertcurrency, payCash: this.payCash, payCard: this.payCard, duration: this.duration, modeId: this.modeId, date: this.datestring, coupon: this.coupon, transferCash: this.transferCash, firstBuyBook: this.fistBuyBool, qtySessions: this.qtySessions, limitTime: this.limitTime,  limitTimeDays: this.limitTimeDays, hidePrice: this.hidePrice, moduleId: this.moduleId, giftId: this.giftId, platformVideoCall: this.platformVideoCall, linkVideoCall: this.linkVideoCall, passVideoCall: this.passVideoCall, roomIdLink: this.roomIdLink}));
            this.saveBooking();
        },

        async saveTempBooking(){ 
            this.tempBook = '';
            var e = JSON.parse(localStorage.getItem('cart'));
            var payload = {
                action: 'save-temp-sales-modules',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                customerId: localStorage.getItem('token'),
                profetionalId: e.profRef,
                publishId: e.ref,
                therapyId: e.therapyId,
                hourUser: e.userHour,
                hourProf: e.originaHour,
                date: e.dateSelected,
                duration: e.duration,
                modality: e.modeId,
                currency: this.pasarelaCurrency,
                pasarela: this.pasarelaId,
                transferCash: this.transferCash,
                totalAmmountOld: this.totalAmmountOldNoFormat,
                totalAmmount: this.payRealPriceNoFormat,
                paid: this.isPaid,
                coupon: this.coupon,
                discount: this.percDesc,
                payCash: this.payCashSelected,
                publishTitle: e.title,
                recurrent: 0,
                publishType: e.type,
                qtySessions: e.qtySessions, 
                limitTime: e.limitTime,
                limitTimeDays: e.limitTimeDays, 
                hidePrice: e.hidePrice,
                giftId: e.giftId,
                moduleId: this.moduleId,
                platformVideoCall: e.platformVideoCall,
                linkVideoCall: e.linkVideoCall,
                passVideoCall: e.passVideoCall,
                roomIdLink: e.roomIdLink
            }
            await axios.post('/index.php', payload)
            .then(result => {
                if(result.data.status == '200'){
                    this.tempBook = result.data.bookRef;
                    if(this.hidePrice){
                        this.payId = 'cash';
                        this.saveBooking();
                        return;
                    }
                    //this.saveBooking();
                    if(this.profCountry != this.userCountry){
                        this.payCash = 0;
                        this.payCard = 1;
                    }
                    if(!localStorage.getItem('token')){
                        localStorage.setItem('cart', JSON.stringify({ref: this.ref,title: this.title, type: this.type, profetional: this.profetional, originaHour: this.hourSelected, userHour:  this.hour, dateSelected: this.originalDate, img: this.imgProf, duration: this.duration, fistPrice: this.fistPrice, promoPrice: this.promoPrice, userCurrency: this.userCurrency, userPrice: this.userPrice, userOldPrice: this.userOldPrice, userIp: this.userIp, userCountry: this.userCountry, profCountry: this.profCountry, realPriceLocal: this.realPriceLocal, realPriceInter: this.realPriceInter, firstPriceLocal: this.firstPriceLocal, firstPriceInter: this.firstPriceInter, promoPriceLocal: this.promoPriceLocal, promoPriceInter: this.promoPriceInter, therapyId: this.therapyId, profRef: this.dataReceiver, payRealPrice: this.payRealPrice, totalAmmountOldNoFormat: this.totalAmmountOldNoFormat, flagCurrency: this.alertcurrency, payCash: this.payCash, payCard: this.payCard, duration: this.duration, modeId: this.modeId, date: this.datestring, coupon: this.coupon, transferCash: this.transferCash, firstBuyBook: this.fistBuyBool, qtySessions: this.qtySessions, limitTime: this.limitTime,  limitTimeDays: this.limitTimeDays, hidePrice: this.hidePrice, moduleId: this.moduleId, giftId: this.giftId, platformVideoCall: this.platformVideoCall, linkVideoCall: this.linkVideoCall, passVideoCall: this.passVideoCall, roomIdLink: this.roomIdLink}));
                        var g = this.$router.push({ name: 'loginsignin'});
                        setTimeout(function() {  g }, 3000);
                    
                    /* var modal = new Modal(document.querySelector('#modal'))
                        modal.show(); */ 
                        return;
                    }
                    if(this.payRealPriceNoFormat == 0){
                    this.payFree();
                    }else{
                        if(this.payCash == 1 && this.payCard == 1){
                            this.openTurns();
                        }else if(this.payCash == 1 && this.payCard == 0){
                            this.payCashTransfer('2');
                        }else{ 
                            localStorage.setItem('cart', JSON.stringify({ref: this.ref,title: this.title, type: this.type, profetional: this.profetional, originaHour: this.hourSelected, userHour:  this.hour, dateSelected: this.originalDate, img: this.imgProf, duration: this.duration, fistPrice: this.fistPrice, promoPrice: this.promoPrice, userCurrency: this.userCurrency, userPrice: this.userPrice, userOldPrice: this.userOldPrice, userIp: this.userIp, userCountry: this.userCountry, profCountry: this.profCountry, realPriceLocal: this.realPriceLocal, realPriceInter: this.realPriceInter, firstPriceLocal: this.firstPriceLocal, firstPriceInter: this.firstPriceInter, promoPriceLocal: this.promoPriceLocal, promoPriceInter: this.promoPriceInter, therapyId: this.therapyId, profRef: this.dataReceiver, payRealPrice: this.totalAmmountOldNoFormat, totalAmmountOldNoFormat: this.totalAmmountOldNoFormat, flagCurrency: this.alertcurrency, payCash: this.payCash, payCard: this.payCard, duration: this.duration, modeId: this.modeId, date: this.datestring, coupon: this.coupon, transferCash: this.transferCash, firstBuyBook: this.fistBuyBool, qtySessions: this.qtySessions, limitTime: this.limitTime,  limitTimeDays: this.limitTimeDays, hidePrice: this.hidePrice, moduleId: this.moduleId, giftId: this.giftId, platformVideoCall: this.platformVideoCall, linkVideoCall: this.linkVideoCall, passVideoCall: this.passVideoCall, roomIdLink: this.roomIdLink}));
                            this.openPasarela();   
                        }  
                    }
                    return;   
                }
            })
            .catch(error => {
                this.loading = false;
                this.disabled = false;
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }	
                return;
            })
        },
        async saveBooking(){  
            this.loadingcart = 'loading-cart on';
            var e = JSON.parse(localStorage.getItem('cart'));
            var payload = {
                action: 'save-sales-modules',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                tempBook: this.tempBook,
                paid: this.isPaid,
                payId: this.payId,  
            }
            await axios.post('/index.php', payload)
            .then(result => {
                if(result.data.status == '200'){
                    localStorage.setItem('sellId', result.data.idRef);
                    localStorage.setItem('gCalendarLink', result.data.linkgCalendar);
                    localStorage.setItem('titular', result.data.titular);
                    localStorage.setItem('bankName', result.data.banco);
                    localStorage.setItem('doc', result.data.dni);
                    localStorage.setItem('userAliasBank', result.data.userAliasBank);  

                    localStorage.setItem('cbu', result.data.cbu);
                    this.loadingcart = 'loading-cart on';
                    if(this.hidePrice){
                        this.$router.push({ name: 'manual-success'});
                        return;
                    }
                    if(this.payCashSelected == 1){
                        if(this.payRealPriceNoFormat == 0){
                            this.$router.push({ name: 'free-success-course'}); 
                        }else{
                            if(this.transferCash == '1'){
                                this.$router.push({ name: 'transfer-success'});
                            }else{
                                this.$router.push({ name: 'cash-success'});
                            }   
                        }         
                    }else{
                        this.$router.push({ name: 'card-success-course'});  
                    }
                    this.disabled = false;
                }
            })
            .catch(error => {
                this.loading = false;
                this.disabled = false;
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }	
                return;
            })
        },
        openTurns(){
            this.classOverlayturns = 'overlayturns in';
            this.classturns = 'turns in';
        },
        closeTurns(){
            this.classOverlayturns = 'overlayturns';
            this.classturns = 'turns';
        },
        modalHide(){          
            localStorage.setItem('cart', JSON.stringify({ref: this.ref,title: this.title, type: this.type, profetional: this.profetional, originaHour: this.hourSelected, userHour:  this.hour, dateSelected: this.originalDate, img: this.imgProf, duration: this.duration, fistPrice: this.fistPrice, promoPrice: this.promoPrice, userCurrency: this.userCurrency, userPrice: this.userPrice, userOldPrice: this.userOldPrice, userIp: this.userIp, userCountry: this.userCountry, profCountry: this.profCountry, realPriceLocal: this.realPriceLocal, realPriceInter: this.realPriceInter, firstPriceLocal: this.firstPriceLocal, firstPriceInter: this.firstPriceInter, promoPriceLocal: this.promoPriceLocal, promoPriceInter: this.promoPriceInter, therapyId: this.therapyId, profRef: this.dataReceiver, payRealPrice: this.payRealPrice, totalAmmountOldNoFormat: this.totalAmmountOldNoFormat, flagCurrency: this.alertcurrency, payCash: this.payCash, payCard: this.payCard, duration: this.duration, modeId: this.modeId, date: this.datestring, coupon: this.coupon, transferCash: this.transferCash, firstBuyBook: this.fistBuyBool, qtySessions: this.qtySessions, limitTime: this.limitTime,  limitTimeDays: this.limitTimeDays, hidePrice: this.hidePrice, moduleId: this.moduleId, giftId: this.giftId, platformVideoCall: this.platformVideoCall, linkVideoCall: this.linkVideoCall, passVideoCall: this.passVideoCall, roomIdLink: this.roomIdLink}));
            var g = this.goToLogin();
            setTimeout(function() {  g }, 3000);	        	 
        },
        
        modalHide2(){          
            localStorage.setItem('cart', JSON.stringify({ref: this.ref,title: this.title, type: this.type, profetional: this.profetional, originaHour: this.hourSelected, userHour:  this.hour, dateSelected: this.originalDate, img: this.imgProf, duration: this.duration, fistPrice: this.fistPrice, promoPrice: this.promoPrice, userCurrency: this.userCurrency, userPrice: this.userPrice, userOldPrice: this.userOldPrice, userIp: this.userIp, userCountry: this.userCountry, profCountry: this.profCountry, realPriceLocal: this.realPriceLocal, realPriceInter: this.realPriceInter, firstPriceLocal: this.firstPriceLocal, firstPriceInter: this.firstPriceInter, promoPriceLocal: this.promoPriceLocal, promoPriceInter: this.promoPriceInter, therapyId: this.therapyId, profRef: this.dataReceiver, payRealPrice: this.payRealPrice, totalAmmountOldNoFormat: this.totalAmmountOldNoFormat, flagCurrency: this.alertcurrency, payCash: this.payCash, payCard: this.payCard, duration: this.duration, modeId: this.modeId, date: this.datestring, coupon: this.coupon, transferCash: this.transferCash, firstBuyBook: this.fistBuyBool, qtySessions: this.qtySessions, limitTime: this.limitTime,  limitTimeDays: this.limitTimeDays, hidePrice: this.hidePrice, moduleId: this.moduleId, giftId: this.giftId, platformVideoCall: this.platformVideoCall, linkVideoCall: this.linkVideoCall, passVideoCall: this.passVideoCall, roomIdLink: this.roomIdLink}));  
            var g = this.goToSignIn();
            setTimeout(function() {  g }, 3000);	      	 
        },
        
        async loadCart(){
            if(localStorage.getItem('cart')){
                var e = JSON.parse(localStorage.getItem('cart'));
                this.title = e.title;
                this.profetional = e.profetional;
                this.originalDate = e.dateSelected;
                this.hour = e.userHour;
                this.hourSelected = e.originaHour;
                this.imgProf = e.img;
                this.ref = e.ref;
                this.userCurrency = e.userCurrency;
                this.userPrice = e.userPrice;
                this.userOldPrice = e.userOldPrice;
                this.fistPrice = e.fistPrice;
                this.promoPrice = e.promoPrice;
                this.realPriceLocal = e.realPriceLocal;
                this.realPriceInter = e.realPriceInter;
                this.firstPriceLocal = e.firstPriceLocal;
                this.firstPriceInter = e.firstPriceInter;
                this.promoPriceLocal = e.promoPriceLocal;
                this.promoPriceInter = e.promoPriceInter;
                this.dataReceiver = e.profRef;
                this.therapyId = e.therapyId; 
                this.payRealPrice = e.payRealPrice; 
                this.totalAmmountOldNoFormat = e.totalAmmountOldNoFormat,
                this.payRealPriceNoFormat = e.payRealPrice; 
                this.modeId = e.modeId; 
                this.payCash = e.payCash;
                this.payCard = e.payCard;
                this.coupon = e.coupon;  
                this.duration = e.duration; 
                this.datestring = e.date;
                this.profCountry = e.profCountry;
                this.userCountry = e.userCountry;
                this.transferCash = 0;
                this.fistBuyBool = e.firstBuyBook;
                this.userIp = e.userIp; 
                this.type = e.type;
                this.qtySessions = e.qtySessions;
                this.limitTime = e.limitTime;
                this.limitTimeDays = e.limitTimeDays;
                this.hidePrice = e.hidePrice;
                this.moduleId = e.moduleId;
                this.giftId = e.giftId;
                this.platformVideoCall = e.platformVideoCall;
                this.linkVideoCall = e.linkVideoCall;
                this.passVideoCall = e.passVideoCall;
                this.roomIdLink = e.roomIdLink;
                

                if(this.modeId == '1'){
                    this.disabledCash = false;
                }else{
                    this.disabledCash = true;
                }
                if(e.flagCurrency == true){
                    this.alertcurrency = true;
                    this.findUserCurrency(); 
                    this.findGlobalPayments();
                }else{
                    this.findLobalPayments();
                }
            }else{
                this.noItems = true;
                this.disabled = true;
            }
        },
        
        checkPay(){
            if(this.$route.params.ref == 'WHhLRlhGUlhyZFVjdnVzQ05XUWI0UT09'){ //aprobado
                this.isPaid = 1;
                this.payCashSelected = 0; 
                this.saveBooking();
            }else if(this.$route.params.ref == 'aXhJMytjOVFCVTZLMWhEMVQ1emVxZz09'){ // rechazado
                this.payCash = 0;
                this.payCard = 0;
                this.isPaid = 0;
                this.$router.push({ name: 'card-refound'});
            }		
        },
        addCommas(nStr) {
            if(this.$route.params.browserCountry == 'us'){
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ',' + '$2');
                }
                return x1 + x2;
            }else{
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2;
                if(this.userCurrency == 'USD'){
                    x2 = x.length > 1 ? '.' + x[1] : '';
                }else{
                    x2 = x.length > 1 ? ',' + x[1] : '';
                }
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + '.' + '$2');
                }
                return x1 + x2;
            }
        },
        async findGlobalPayments(){  
            var payload = {
                action: 'find-global-payments',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.dataReceiver
            }
            await axios.post('/index.php', payload)
            .then(result => {
                if(result.data.status == '200'){
                     if(result.data.secretKey == "" && result.data.publicKey == ""){
                        this.pasarela = 'stripe';
                        this.pasarelaId = result.data.pasarela;
                        this.pasarelaCurrency = result.data.currency;
                     }else{
                         if(result.data.checked == true){
                            this.pasarela = 'payPal';
                            this.secretKey = result.data.secretKey;
                            this.publicKey = result.data.publicKey;
                            this.pasarelaId = result.data.pasarela;
                            this.pasarelaCurrency = result.data.currency;
                         }else{
                            this.pasarela = 'stripe';
                            this.pasarelaId = result.data.pasarela;
                            this.pasarelaCurrency = result.data.currency;
                         }
                     }
                     if(this.hidePrice == true){
                        if(!localStorage.getItem('token')){
                                this.$router.push({ name: 'loginsignin'});
                            return;
                        }else{
                            this.saveTempBooking();
                            return;
                        }
                    }
                } 

                    this.checkPay();
                 
            })
            .catch(error => {
                this.loading = false;
                this.disabled = false;
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }	
                return;
            })
        },
        async findLobalPayments(){  
            var payload = {
                action: 'find-local-payments',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.dataReceiver
            }
            await axios.post('/index.php', payload)
            .then(result => {         
                if(result.data.status == '200'){
                     if(result.data.secretKey == "" && result.data.publicKey == ""){ 

                        
                        if(result.data.userCountry == '5' || result.data.userCountry == '42' || result.data.userCountry == '81'){
                            this.pasarela = 'dLocal';
                            this.pasarelaId = result.data.pasarela;
                            this.pasarelaCurrency = result.data.currency;
                            this.disabledPayCard = true;
                        }else if(result.data.userCountry == '55' || result.data.userCountry == '103'){
                            this.pasarela = 'stripe';
                            this.pasarelaId = result.data.pasarela;
                            this.pasarelaCurrency = result.data.currency;
                            this.disabledPayCard = true;
                        }else{
                            this.disabledPayCard = false;
                            this.pasarelaId = result.data.pasarela;
                            this.pasarelaCurrency = result.data.currency;
                        }
                     }else{
                        if(result.data.checked == true){
                            if(result.data.pasarela == '3'){
                                this.pasarela = 'mercadoPago';
                            }else if(result.data.pasarela == '4'){
                                this.pasarela = 'payPal';
                            } 
                            this.secretKey = result.data.secretKey;
                            this.publicKey = result.data.publicKey;
                            this.pasarelaId = result.data.pasarela;
                            this.pasarelaCurrency = result.data.currency;
                            this.disabledPayCard = true;
                         }else{
                            if(result.data.userCountry == '5' || result.data.userCountry == '42' || result.data.userCountry == '81'){
                                this.pasarela = 'dLocal';
                                this.pasarelaId = result.data.pasarela;
                                this.pasarelaCurrency = result.data.currency;
                                this.disabledPayCard = true;
                            }else if(result.data.userCountry == '55' || result.data.userCountry == '103'){
                                this.pasarela = 'stripe';
                                this.pasarelaId = result.data.pasarela;
                                this.pasarelaCurrency = result.data.currency;
                                this.disabledPayCard = true;
                            }else{
                                this.disabledPayCard = false;
                                this.pasarelaId = result.data.pasarela;
                                this.pasarelaCurrency = result.data.currency;
                            }
                         } 
                     }
                     if(this.hidePrice == true){
                        if(!localStorage.getItem('token')){
                                this.$router.push({ name: 'loginsignin'});
                            return;
                        }else{
                            this.saveTempBooking();
                            return;
                        }
                    }
                }   

                this.checkPay();
                
            })
            .catch(error => {
                this.loading = false;
                this.disabled = false;
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }	
                return;
            })
        },
        confirmPay(){
            if(!localStorage.getItem('token')){
                localStorage.setItem('cart', JSON.stringify({ref: this.ref,title: this.title, type: this.type, profetional: this.profetional, originaHour: this.hourSelected, userHour:  this.hour, dateSelected: this.originalDate, img: this.imgProf, duration: this.duration, fistPrice: this.fistPrice, promoPrice: this.promoPrice, userCurrency: this.userCurrency, userPrice: this.userPrice, userOldPrice: this.userOldPrice, userIp: this.userIp, userCountry: this.userCountry, profCountry: this.profCountry, realPriceLocal: this.realPriceLocal, realPriceInter: this.realPriceInter, firstPriceLocal: this.firstPriceLocal, firstPriceInter: this.firstPriceInter, promoPriceLocal: this.promoPriceLocal, promoPriceInter: this.promoPriceInter, therapyId: this.therapyId, profRef: this.dataReceiver, payRealPrice: this.payRealPrice, totalAmmountOldNoFormat: this.totalAmmountOldNoFormat, flagCurrency: this.alertcurrency, payCash: this.payCash, payCard: this.payCard, duration: this.duration, modeId: this.modeId, date: this.datestring, coupon: this.coupon, transferCash: this.transferCash, firstBuyBook: this.fistBuyBool, qtySessions: this.qtySessions, limitTime: this.limitTime,  limitTimeDays: this.limitTimeDays, hidePrice: this.hidePrice, moduleId: this.moduleId, giftId: this.giftId, platformVideoCall: this.platformVideoCall, linkVideoCall: this.linkVideoCall, passVideoCall: this.passVideoCall, roomIdLink: this.roomIdLink}));
                var g = this.$router.push({ name: 'loginsignin'});
                setTimeout(function() {  g }, 3000);
            
            /* var modal = new Modal(document.querySelector('#modal'))
                modal.show(); */ 
                return;
            }
            this.saveTempBooking();
           
        },
        openPasarela(){
            this.closeTurns();
            if(this.pasarela == 'mercadoPago'){
                this.mercadoPago();
            }else if(this.pasarela == 'payPal'){
                this.payPal();
            }else if(this.pasarela == 'stripe'){
                this.stripe();
            }else if(this.pasarela == 'dLocal'){
                this.dLocal();
            }
        },
        async payPal(){
            var t = this.title;
            this.buttonPasarela = url + '/pasarelas/paypal-option.php?browserCountry='+this.$route.params.browserCountry
            +'&userLang='+this.$route.params.userLang+'&urlcode='+window.location.host+'&tempBook='+this.tempBook+'&dataReceiver='+this.dataReceiver+'&item='+localStorage.getItem('token')+'&price='+this.payRealPriceNoFormat+'&currency='+this.pasarelaCurrency
            +'&publicKey='+this.publicKey+'&pasarela='+this.pasarelaId+'&back='+window.location.href+"&description="+t+"&short=Animasana&mode=2";
            this.pay = true;
            this.hidecontent = true;
            document.querySelector('html').classList.add('overflow-hidden');          
        },
        async mercadoPago(){
            var t = this.title;
            this.buttonPasarela = url + '/pasarelas/mp-option.php?browserCountry='+this.$route.params.browserCountry
            +'&userLang='+this.$route.params.userLang+'&urlcode='+window.location.host+'&tempBook='+this.tempBook+'&dataReceiver='+this.dataReceiver+'&item='+localStorage.getItem('token')+'&price='+this.payRealPriceNoFormat+'&currency='+this.pasarelaCurrency
            +'&publicKey='+this.publicKey+'&pasarela='+this.pasarelaId+'&back='+window.location.href+"&description="+t+"&short=Animasana&mode=2";
            this.pay = true;
            this.hidecontent = true;
            document.querySelector('html').classList.add('overflow-hidden');          
        },
        async stripe(){
            var t = this.title;
            this.buttonPasarela = url + '/pasarelas/stripe-option.php?browserCountry='+this.$route.params.browserCountry
            +'&userLang='+this.$route.params.userLang+'&urlcode='+window.location.host+'&tempBook='+this.tempBook+'&dataReceiver='+this.dataReceiver+'&item='+localStorage.getItem('token')+'&price='+this.payRealPriceNoFormat+'&currency='+this.pasarelaCurrency
            +'&publicKey='+this.publicKey+'&pasarela='+this.pasarelaId+'&back='+window.location.href+"&description="+t+"&short=Animasana&mode=2";
            this.pay = true;
            this.hidecontent = true;
            document.querySelector('html').classList.add('overflow-hidden');          
        },
        async dLocal(){
            var t = this.title;
            this.buttonPasarela = url + '/pasarelas/dlocal-option.php?browserCountry='+this.$route.params.browserCountry+'&userLang='+this.$route.params.userLang+'&urlcode='+window.location.host+'&tempBook='+this.tempBook+'&dataReceiver='+this.dataReceiver+'&item='+localStorage.getItem('token')+'&price='+this.payRealPriceNoFormat+'&currency='+this.pasarelaCurrency
                +'&publicKey='+this.publicKey+'&pasarela='+this.pasarelaId+'&back='+window.location.href+"&description="+t+"&short=Animasana&mode=2";
            this.pay = true;
            this.hidecontent = true;
            document.querySelector('html').classList.add('overflow-hidden');          
        }
       
    }


}
</script>
<style scoped>
@media (max-width: 575px){
#couponStep1{
    width: 80%;
}
}
.leyed-qtySesion{
    font-size: 12px;
    color: #7b7b7b;
    margin: 0 10px;
    line-height: 12px;
    text-align: center;
    margin-bottom: 10px;
}
</style>