<template>
    <div>
        <iframe :src="url"></iframe>
        <button class="btn btn-primary w-50 btn-iframe" @click="closePdf()">Cerrar</button>
    </div>
</template>
<script>
export default {   
    props: ['url'],
    name: "pdfReaderIframe",
    methods:{
        closePdf(){
            this.$root.closePdf();
        }
    }
   
}
</script>

<style scoped>
div{
    background: #fff;
    position: absolute;
    z-index: 2000001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
iframe{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.btn-iframe{
    position: absolute;
    z-index: 2;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: auto;
}
</style>