<template>
    <div class="sandbox-badge" v-if="moduleFree == '1' && profRef == dataReceiver">
        <small class="m-0 d-block">Deberán</small>
        <small class="m-0 d-block">contratar el módulo</small>
    </div>
    <div v-if="url2 != '' && moduleFree == '0'" class="text-center d-flex justify-content-between align-items-center pdf-pagination" style="position: relative;">
        <template v-if="isLoading">Cargando...</template>
        <div v-if="!isLoading" class="menu-pdf shadow">         
            <a class="btn btn-primary" :disabled="disabledPrev" @click="prevPage()"><i class="las la-angle-left"></i></a>
            <span class="text-white">{{ pages }} / {{ pageCount }}</span>
            <a class="btn btn-primary" :disabled="disabledNext" @click="nextPage()"><i class="las la-angle-right"></i></a> &nbsp;&nbsp;
            <a class="btn btn-primary" @click="zoomOut()"><i class="las la-search-minus"></i></a>
            <a class="btn btn-primary" @click="zoomIn()"><i class="las la-search-plus"></i></a> &nbsp;&nbsp;
            <a class="btn btn-primary" v-if="canDownload" @click="downloadPdf()"><i class="las la-save"></i></a>
        </div>
    </div>
    <button v-if="moduleFree == '0'" @click="showFullPdf()" class="btn btn-outline-primary btn-sm btn-fullscreen" title="Agrandar - Achicar"><i :class="classIcon"></i> {{fulltText}}</button>
    <div class="w-100 text-center container-lesson d-flex justify-content-center align-items-center" v-if="url == ''"> 
        <img src="@/assets/imgs/spinner.webp" class="img-fluid waiting-img-lesson" />
    </div>
    
    <div class="w-100 text-center container-lesson" :class="fullpdf" v-show="url != ''" id="material">  
        <div v-if="moduleFree == '1' && profRef != dataReceiver" class="text-center container-question initial-quiz in d-flex justify-content-center align-items-center">
            <div>
                <payment-cart v-if="profRef != ''" :profRef="profRef" :couserRef="couserRef" :publishTitle="publishTitle" :moduleName="moduleName" :lessonTitle="titleClass" :moduleId="moduleId" :realPriceInter="realPriceInter" :realPriceLocal="realPriceLocal"></payment-cart>
            </div>
        </div>  
        <div class="pdf-ccontainer" v-if="moduleFree == '0' || profRef == dataReceiver">  
           <!--<iframe :src="url"></iframe>-->
           
            <div v-if="url != ''" class="pdf-container">
                <vue-pdf-embed :width="doc_width" ref="pdfRef" :source="url"  :page="pages" @rendered="handleDocumentRender" :disableTextLayer="true" />       
            </div>
        </div>           
    </div>
    <div class="hide-horizontal" v-if="moduleFree == '0' || profRef == dataReceiver">
        <div class="options-lesson" :class="fullpdf">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="d-block d-lg-none mt-2">
                            <div class="d-flex justify-content-start align-items-center">
                                <img :src="profImg" class="img-user" />
                                <p class="m-0">{{ profName }}</p>
                            </div>
                            <p class="m-0 mt-2">{{moduleName}}</p>
                            <h4 class="titleLesson mb-4 mt-0">{{ titleClass }}</h4>
                        </div>  
                        <div class="d-none d-lg-flex justify-content-between align-items-center mt-4 mb-4">
                            <div>
                                <p class="m-0">{{moduleName}}</p>
                                <h3 class="titleLesson mb-0 mt-0">{{ titleClass }}</h3>
                            </div>
                            <div class="d-flex justify-content-start align-items-center">
                                <img :src="profImg" class="img-user" />
                                <p class="m-0">{{ profName }}</p>
                            </div>
                        </div>   
                        <div class="description-lesson mb-4" v-html="descriptionPrivate"></div>
                        <div class="mb-5 text-center">
                            <img src="@/assets/imgs/logo-white.png" class="mx-auto mt-5 mb-5" style="width: 152px; filter: brightness(0.5);" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed'
import axios from "axios";
import paymentCart from '@/components/course-show/payment-cart.vue'  
import { Toast } from 'bootstrap';
export default {   
    name: "pdf-module",
    props: ['moduleId', 'lessonId', 'couserRef', 'profName', 'profImg', 'moduleName', 'localZone', 'publishTitle', 'bookingId'], 
    data(){
        return{
            errorText: '',
            toastColor: '',
            toastBg: '',
            toastNumber: 0,
            dataReceiver: '',
            url: '',
            titleClass: '',
            typeClass: 1,
            duration: 0,
            descriptionPrivate: '',
            fullpdf: '',
            classIcon: 'las la-expand-arrows-alt',
            fulltText: 'Agrandar',
            disabledNext: true,
            disabledPrev: true,
            pages: 1,
            pageCount: 1,
            isLoading: true,
            scales: 1, 
            doc_width: '300',
            canDownload: false,
            moduleFree: 0,
            profRef: '',  
            realPriceLocal: 0,
            realPriceInter: 0,
        }
    },
    components: {
        VuePdfEmbed,
        paymentCart
    },
    mounted: function () {
        this.dataReceiver = localStorage.getItem('token');
        this.findLessonData()
    },
 
    methods:{
        downloadPdf() {
            const link = document.createElement("a");
            link.href = this.url;
            link.click();   
        },
        handleDocumentRender() {
            this.isLoading = false
            this.pageCount = this.$refs.pdfRef.pageCount
        },
        nextPage(){
            if(this.pages < this.pageCount){
                this.pages++;
                this.disabledNext = false;
            }else{
                this.disabledNext = true;
            }
            if(this.pages < 2){
                this.disabledPrev = true;
            }else{
                this.disabledPrev = false;
            }
        },
        prevPage(){ 
            if(this.pages < 2){
                this.disabledPrev = true;
            }else{
                this.pages--;
                this.disabledPrev = false;
            }
            if(this.pages < (this.pageCount - 1)){
                this.disabledNext = false;
            }else{
                this.disabledNext = true;
            }
        },
        zoomIn() {
            this.doc_width = (this.doc_width * 1.2);
        },
        zoomOut() {
            this.doc_width = (this.doc_width / 1.2);
        },
        showFullPdf(){
            if(this.fullpdf == ''){
                this.classIcon = 'las la-compress-arrows-alt';
                this.fulltText = 'Achicar';
                this.fullpdf = 'full';
            }else{
                this.classIcon = 'las la-expand-arrows-alt';
                this.fulltText = 'Agrandar';
                this.fullpdf = '';
            }
        },
        isPlaying(){
        },
        isFinish(){
            this.$parent.markReady(this.lessonId, this.moduleId)
        },
        async findLessonData(){
            var payload = {
                action: 'find-lesson-data-workshop',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.dataReceiver,
                couserRef: this.couserRef,
                moduleId: this.moduleId,
                lessonId: this.lessonId,
                bookingId: this.bookingId
            }
            await axios.post('/index.php', payload)
            .then(result => {
                this.waiting = true;
                this.titleClass = result.data.titleClass;
                this.typeClass = result.data.typeClass;
                this.duration = result.data.duration;       
                this.descriptionPrivate = result.data.descriptionPrivate;
                this.realPriceLocal = result.data.realPriceLocal;
                this.realPriceInter = result.data.realPriceInter;
                this.profRef = result.data.userId;
                this.moduleFree = result.data.moduleFree;
                if(this.moduleFree == '1'){
                    this.$parent.hideShowBtnMessage(true);
                }else{
                    this.$parent.hideShowBtnMessage(false);
                } 
                this.url = result.data.pdfFile;
                if(this.moduleFree == '0'){
                    this.canDownload = result.data.downloadPdf;      
                    this.pages = 1;
                    if(window.innerWidth < 592){
                        var a = this;
                        setTimeout(function(){ a.showFullPdf() }, 2000);
                    }
                    this.isFinish(); 
                }
            });
        },    
    }
}
</script>