<template>
    <div class="col-12 mb-0 details text-center"> 
        <p class="m-0">{{moduleName}}</p>
        <h5 class="therapy-name m-0">{{ lessonTitle }}</h5> 
    </div>
    <i class="module-lock-icon text-warning las la-lock"></i>
    <p class="text-center mt-2 fst-italic small lh-1">Debes contratar el módulo "<b>{{moduleName}}</b>"" para poder acceder a este y otros capítulos que contenga.</p>
    <cart-confirmation-modules v-if="showCart"></cart-confirmation-modules>
</template>
<script>
import axios from "axios";
import { Toast } from 'bootstrap';
import cartConfirmationModules from '@/components/cart/cart-confirmation-modules.vue'  
export default {   
    name: "payment-cart",
    props: ['profRef', 'couserRef', 'publishTitle', 'moduleName', 'lessonTitle', 'moduleId', 'realPriceLocal', 'realPriceInter'], 
    data(){
        return{
            errorText: '',
            toastColor: '',
            toastBg: '',
            toastNumber: 0,
            dataReceiver: '',
            userCountry: 0, 
            profCountry: 0,
            flagCurrency: false,
            payRealPrice: 0,
            userPrice: 0,
            userCurrency: '',
            showCart: false
        }
    },
    components: {
        cartConfirmationModules,
    },
    mounted: function () {
        this.dataReceiver = localStorage.getItem('token');
        this.findAddressInfo()
    },
    methods:{   

        async findAddressInfo(){
            var payload = {
                action: 'find-user-address',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.profRef     
            }
            await axios.post('/index.php', payload)
            .then(result => {
                 if(result.data.status == '200'){
                    this.profCountry = result.data.userCountryCode;
                    var data = JSON.parse(localStorage.getItem('userData'));
                    this.userCountry = data.userCountry;
                    this.printValues(); 
                 }  
            })
            .catch(error => {  
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }	
                return;
            })
        },
        printValues(){  
            if(this.userCountry == this.profCountry){
                this.flagCurrency = false;
                var priceA = this.realPriceLocal; 
                this.payRealPrice = priceA;
                this.convertPrice(priceA, 'price', 'Yes');
                this.findUserCurrency();
            }else{
                this.flagCurrency = true;   
                var priceA = this.realPriceInter; 
                this.payRealPrice = priceA;
                this.convertPrice(priceA, 'price', 'Yes');
                this.userCurrency = "USD";  
                this.createCart();
            }
        },
        async convertPrice(val, val2, val3){
            var payload = {
                action: 'convert-price',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                userCountryId: this.userCountry,
                userCurrency: this.userCurrency,
                profCurrency: 'USD',
                onlyFormat: val3,
                price: val
            }
            await axios.post('/index.php', payload).then(result => {
                if(val2 == 'price'){
                    this.userPrice = result.data.price;
                }
            })         
        },
        async findUserCurrency(){
            var payload = {
                action: 'find-currency',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                userCountry: this.profCountry     
            }
            await axios.post('/index.php', payload)
            .then(result => {      
                this.userCurrency = result.data[0].name;  
                this.createCart();
            })
            .catch(error => { 
                this.errorText = 'Revise su conexión de internet.2';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }	
                return;
            })
        },

        createCart(){
            var d = new Date().toISOString().substr(0, 19).replace('T', ' ');
            var datestring = d;
            var data = JSON.parse(localStorage.getItem('userData'));
            localStorage.setItem('cart', JSON.stringify({ref: this.couserRef, title: this.publishTitle + ' - Módulo: ' + this.moduleName, firstBuyBook: false, type: 'NGVCTkNwM0FVbHJpN3B1SUNIOHFoUT09', profetional: '', originaHour: '', userHour:  '', dateSelected: '', img: '', duration: '', promoPrice: 0, fistPrice: 0, userCurrency: this.userCurrency, userPrice: this.userPrice, userOldPrice: 0, userIp: '', userCountry: data.userCountry, profCountry: this.profCountry, realPriceLocal: this.realPriceLocal, realPriceInter: this.realPriceInter, promoPriceLocal: 0, promoPriceInter: 0, profRef: this.profRef, payRealPrice: this.payRealPrice, totalAmmountOldNoFormat: this.payRealPrice, flagCurrency: this.flagCurrency, payCash: this.payCash, payCard: this.payCard, duration: '',  date: datestring, coupon: '', qtySessions: '', limitTime: '',  limitTimeDays: '', hidePrice: false, moduleId: this.moduleId, platformVideoCall: '', linkVideoCall: '', passVideoCall: '', roomIdLink: ''}));
            this.showCart = true; 
        }
    }
}

</script>
