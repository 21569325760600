<template>
    <div id="myHelp" class="prevCourse" :class="maximize">
        <!-- Include a header DIV with the same name as the draggable DIV, followed by "header" -->
        <div id="myHelpheader"> 
            <div><span>{{title}}</span></div>
            <div>
                <i class="las la-braille"></i> 
                <i class="las la-times pointer" @click="closeModal()"></i>
                <i class="las la-redo-alt pointer" title="Refrescar" :class="maximizeIcon" @click="refresh()"></i>
                <i class="las pointer" :class="maximizeIcon" @click="maxMin()"></i>
                <span class="pointer" @click="maxMin()">{{textMaximize}}</span>
                
            </div>
        </div>
        <div class="content-help" :class="full">
            <div class="content-help-info" :class="overflow">
                <div class="course-show">
                    <div v-if="typeSelected == 'q'">
                        <quiz-module-preview v-if="previewId != '' && typeSelected == 'q'" :previewId="selectedId"></quiz-module-preview>
                    </div>       
                    <div v-if="typeSelected == 'c'">
                        <iframe class="iframe-preview-course" v-if="url != ''" :src="url" frameborder="0"></iframe>    
                    </div>
                </div>
            </div>
            
        </div>
    </div>

</template>
<script>
import videoModule from '@/components/course-show/video-module.vue'   
import pdfModule from '@/components/course-show/pdf-module.vue' 
import quizModule from '@/components/course-show/quiz-module.vue' 
import quizModulePreview from '@/components/course-show/quiz-module-preview.vue' 
import liveModule from '@/components/course-show/live-module.vue' 
import onlineModule from '@/components/course-show/online-module.vue' 
export default {
    props: ['previewId', 'previewType'],
    data(){
        return{  
            title: 'Vista Previa',
            maximize: '',
            maximizeIcon: 'las la-mobile-alt',
            full: 'full',   
            url: '',
            overflow: 'overflow-auto',
            textMaximize: 'Mobile',
            selectedId: this.previewId,
            typeSelected: this.previewType
        }
    },
    components: {
        videoModule,
        pdfModule,
        quizModule,
        quizModulePreview,
        liveModule,
        onlineModule
    },

    mounted: function () {  
        this.dragElement(document.getElementById("myHelp")); 
        if(this.previewType == 'c'){
            if(window.location.host == 'h.animasana.net' || window.location.host == 'animasana.net'){
                this.url = 'https://' + window.location.host + '/' + this.$route.params.browserCountry + '/' + this.$route.params.userLang + '/lesson/' + this.selectedId; 
            }else if(window.location.host == 'qa.front-ews.pages.dev'){   
                this.url = 'https://' + window.location.host + '/' + this.$route.params.browserCountry + '/' + this.$route.params.userLang + '/lesson/' + this.selectedId; 
            }else{        
                this.url = 'http://' + window.location.host + '/' + this.$route.params.browserCountry + '/' + this.$route.params.userLang + '/lesson/' + this.selectedId;      
            }
            this.overflow = 'overflow-hidden';
        }else{
            this.overflow = 'overflow-auto';
        }
    },  
    methods: {
        refresh(){
            var a = this.selectedId;
            var b = this.typeSelected;
            this.selectedId = '';
            this.typeSelected = '';
            var c = this;
            setTimeout(function(){ 
                c.selectedId = a;
                c.typeSelected = b;
                if(c.previewType == 'c'){
                    if(window.location.host == 'h.animasana.net' || window.location.host == 'animasana.net'){
                        c.url = 'https://' + window.location.host + '/' + c.$route.params.browserCountry + '/' + c.$route.params.userLang + '/lesson/' + c.selectedId; 
                    }else if(window.location.host == 'qa.front-ews.pages.dev'){   
                        c.url = 'https://' + window.location.host + '/' + c.$route.params.browserCountry + '/' + c.$route.params.userLang + '/lesson/' + c.selectedId; 
                    }else{        
                        c.url = 'http://' + window.location.host + '/' + c.$route.params.browserCountry + '/' + c.$route.params.userLang + '/lesson/' + c.selectedId;      
                    }
                }
            }, 200);
            this.selectedId = c.selectedId;
            this.typeSelected = c.typeSelected;

        },
        closeModal(){
            this.showModal = false;
            this.$root.closePreview('');
        },
        maxMin(){
            if(this.maximize == ''){
                this.maximize = 'maximize';
                this.maximizeIcon = 'las la-desktop';  
                this.textMaximize = 'Desktop';  
            }else{
                this.maximize = '';
                this.maximizeIcon = 'las la-mobile-alt';
                this.textMaximize = 'Mobile';
            }
        },
        dragElement(elmnt) {
                var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
                if (document.getElementById(elmnt.id + "header")) {
                    // if present, the header is where you move the DIV from:
                    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
                } else {
                    // otherwise, move the DIV from anywhere inside the DIV:
                    elmnt.onmousedown = dragMouseDown;
                }
                function dragMouseDown(e) {
                    e = e || window.event;
                    e.preventDefault();
                    // get the mouse cursor position at startup:
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    document.onmouseup = closeDragElement;
                    // call a function whenever the cursor moves:
                    document.onmousemove = elementDrag;
                }
                function elementDrag(e) {
                    e = e || window.event;
                    e.preventDefault();
                    // calculate the new cursor position:
                    pos1 = pos3 - e.clientX;
                    pos2 = pos4 - e.clientY;
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    // set the element's new position:
                    if((elmnt.offsetTop - pos2) < 0){
                        elmnt.style.top =  "0px";
                    }else if((elmnt.offsetTop - pos2) > (window.innerHeight - 100)){
                       elmnt.style.top = (window.innerHeight - 100) + "px";
                    }else{
                        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                    }
                    if((elmnt.offsetLeft - pos1) > (window.innerWidth - 100)){
                        elmnt.style.left = (window.innerWidth - 100) + "px";
                    }else if((elmnt.offsetLeft - pos1) < -880){

                    }else{
                        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
                    }
                    
                }
                function closeDragElement() {
                    // stop moving when mouse button is released:
                    document.onmouseup = null;
                    document.onmousemove = null;
                }
        },

    }
}
</script>
<style>
.prevCourse{
    width: 325px !important;
    height: 563px !important;
}
.prevCourse.maximize{
    width: 100% !important;
    height: 90vh !important;
}
.iframe-preview-course{
    position: relative;
    width: 100%;
    height: 519px;
    border: none;
    overflow: auto;
}
.maximize .iframe-preview-course{
    height: 820px;
}
.course-show .ambient-mode{
    position: absolute;
    align-self: center;
    filter: blur(100px);
    width: 100%;
    max-width: 1200px;
    aspect-ratio: 16 / 9;
    opacity: 0.5;
    overflow: hidden;
}
.course-show .ambient-mode img{
    width: 130%;
    max-height: 100%;
}
.course-show{
    height: 100vh;
    background: #0f0f0f;
}
.course-show .dash-view{
    background-color: #0f0f0f;
}

.course-show .lesson-item{
    margin-left: 20px;
    width: calc(100% - 20px);
    padding-top: 13px;
    padding-bottom: 13px;
    color: #c1c2c5;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid #252525;
}
.course-show .accordion-button, .course-show .accordion-button:not(.collapsed){
    border: 1px solid rgba(0,0,0,.125);
    color: #c1c2c5;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid #252525;
}
.course-show .accordion-button.doit, .course-show .accordion-button.doit:not(.collapsed){
    color: #c1c2c5 !important;
    background-color: #2b2a30;
    cursor: pointer;
}
.course-show .module-item-body, .course-show .accordion-item {
    border: none;
    background: #0f0f0f;
}
.course-show .lesson-item.doit{
    color: #c1c2c5;
    background-color: #2b2a30;
    cursor: pointer;
}
.course-show .lesson-item.doit:hover{
    background-color: #403e47;
}
.course-show .lesson-item.now-item{
    color: #c1c2c5;
    background-color: #0c63e4;
    font-weight: 600;
    cursor: default;
}
.course-show .course-items-mmenu-btn {
    display: none;
}
.course-show .course-items-mmenu {
    background: #0f0f0f;
    
}
@media (min-width: 991px){
    .course-show .course-items-mmenu {
        display: block !important;
    }
}
@media (max-width: 991px){
    .course-show .course-items-mmenu-btn {
        display: block;
        position: fixed;
        right: 14px;
        border: 1px solid;
        border-radius: 50px;
        font-size: 14px !important;
        top: 0;
        z-index: 3;
        line-height: 14px;
        width: 172px;
        margin-right: 0;
        background: #0f0f0f;
        
    }
    .course-show .course-items-mmenu {
        position: fixed;
        top: 11px;
        z-index: 2;
        background: #0f0f0f;
        width: 50%;
        border-radius: 5px;
        border: 1px solid #585151;
        overflow: auto;
        height: calc(100% - 50px) !important;
        left: auto;
        right: 13px;
        margin: 0;
        margin-top: 30px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding: 0 !important;
    }
}
@media (max-width: 767px){
    .course-show .course-items-mmenu {
        width: 80%;
    }
}
.course-show .waiting-img-lesson{
    width: 200px;
}
@media (max-width: 767px){
    .course-show .waiting-img-lesson{
        width: 100px;
    }
}
.course-show .titleLesson{
    margin-top: 20px;
    padding-bottom: 0;
    font-weight: 400;
    color: #c1c2c5;
}
.course-show .options-lesson{
    border-top: 1px solid;
}
.course-show .options-lesson.full{
    display: none !important;
}
.course-show .options-lesson .description-lesson{
    border-radius: 12px;
    background: #272727;
    padding: 10px;
    color: #fff;
}
.course-show .options-lesson .description-lesson span, .course-show .options-lesson .description-lesson p {
    color: #fff !important;
    background: #272727 !important;
    background-color: #272727 !important;
    padding: 0 !important;
}
@media (max-width: 820px){
    .course-show .options-lesson {
        border-top: 1px solid;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        padding-top: 28px;
        position: fixed;
        overflow-y: auto;
        height: calc(100% - 66.25vw);
        top: 66.25vw;
        z-index: 0;
        width: 100%;
    }
}
@media (max-width: 720px){
    .course-show .options-lesson{
        border-top: none;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        padding-top: 66.25vw;
        position: fixed;
        overflow-y: auto;
        height: calc(100% - 63px);
        top: 63px;
        z-index: 0;
        width: 100%;
    }
}
.course-show .container-video-lesson, .course-show .container-lesson{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 55.25vw;
    max-height: calc(100vh - 169px);  
    overflow-x: clip;
    overflow: clip;
}
@media (max-width: 767px){
    .course-show .container-video-lesson, .course-show .container-lesson{
        height: 77.25vw;
    }
}
@media (orientation: landscape) and (max-width: 991px){
    .course-show .container-video-lesson, .course-show .container-lesson{
        max-height: 100vh;  
    }
}
@media (orientation: portrait) and (max-width: 991px){
    .course-show .container-video-lesson, .course-show .container-lesson {
        max-height: 100vh;
        padding-top: 50px;
        position: fixed;
        padding-bottom: 10px;
        z-index: 2;
        height: 63.25vw;
    }
}
@media (orientation: portrait) and (max-width: 720px){
    .course-show .container-video-lesson, .course-show .container-lesson{
        max-height: 100vh;
        padding-top: 50px;
        position: fixed;
        padding-bottom: 10px;
        z-index: 2;

    }
}
.course-show .container-video-lesson div, .course-show .container-lesson div{
    width: 100%;
}
.course-show .container-video-lesson iframe{
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
}
@media (orientation: landscape) and (max-width: 991px){
    .course-show .hide-horizontal{
        display: none !important;
    }
}
.course-show .img-user{
    max-width: 48px;
    border-radius: 50%;
    margin-right: 10px;
}

.course-show .container-lesson{
    -webkit-transition: height 0.4s; 
    -moz-transition: height 0.4s; 
    -ms-transition: height 0.4s; 
    -o-transition: height 0.4s; 
    transition: height 0.4s;  
    
}
.course-show .container-lesson .pdf-ccontainer{
    width: 100%;
    position: relative;
    height: 100%;
    margin: auto;
}
@media (min-width: 768px){
    .course-show .container-lesson .pdf-ccontainer{
        height: 80%;
    }
    .course-show .container-lesson.full .pdf-ccontainer{
        margin-top: 45px;
        height: calc(100% - 45px);
    }
}
.course-show .container-lesson.full{
    height: 100vh;
    max-height: 100vh;
}
.course-show .container-lesson iframe{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.course-show .btn-fullscreen {
    position: fixed;
    z-index: 3;
    top: 7px;
    left: 10px;
}
.container-question{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 15px;
    transition: left 1s;
    left: 100%;
    position: absolute;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.container-question.in{
    left: 0;
}
.container-question.hide{
    left: -100% !important;
}
.container-question.initial-quiz{
    color: #c1c2c5;
}
.container-question.finish-quiz{
    color: #c1c2c5;
}
.container-question .question{
    font-size: 3vh;
    padding: 38px 10px;
    color: #c1c2c5;
}
.container-question .question-adjunt{
    position: relative !important;
    height: 230px;
    width: 100%;
}
.container-question .question-adjunt p{
    font-size: 2.2vh;
    font-style: italic;
    color: #86939e;
    margin-bottom: 5px;
}
.container-question .question-adjunt img{
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.container-question .question-adjunt.h-auto{
    height: auto;
}

.container-question .option{
    font-size: 2.6vh;
    padding: 15px 10px;
    border: 1px solid;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 12px;
}
.container-question .option:hover{
    background: #dddddd21;
}
.container-question .option.selected{
    background: #fff;
}
.container-question .option p{
    margin: 0 !important;
}

.container-lesson .countdown{
    
    margin: auto;
    left: 0;
    right: 0;
    font-size: 22px;
    border: 1px solid;
    padding: 7px;
    width: 174px;
    border-radius: 5px;
    top: 0;
    background: #00000096;
}
</style>