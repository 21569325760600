<template>
    
    <div class="w-100 text-center container-video d-flex justify-content-center align-items-center" v-if="quizId == ''"> 
        <img src="@/assets/imgs/spinner.webp" class="img-fluid waiting-img-lesson" />
    </div>
    <div class="w-100 text-center container-lesson full" v-show="quizId != ''" id="material">  
        <div style="position: relative;">
            <vue-countdown v-if="counterFinish != ''" :time="counterFinish" v-slot="{ hours, minutes, seconds }" @end="onCountdownEnd" class="countdown">
                <i class="las la-stopwatch"></i> {{ hours }} : {{ minutes }} : {{ seconds }}
            </vue-countdown>
            <div class="container-question initial-quiz in d-flex justify-content-center align-items-center" id="initial">
                <div class="h-100">
                    <h3 class="mb-5 mt-5">{{titleClass}}</h3>
                    <p v-if="timeQuiz > 0"><i class="las la-stopwatch"></i> Tendrás {{timeQuiz}} minutos para realizarlo.</p>
                    <p>El cuestionario consta de {{questions.length}} preguntas.</p>
                    <p v-if="okPoints != '0'">Cada pregunta otorga un puntaje y para aprobar el cuestionario deberás alcanzar al menos {{okPoints}} puntos.</p>
                    <div class="mt-5 pb-5">
                        <button class="btn btn-outline-primary btn-sm" @click="startQuiz()">Comenzar Cuestionario</button>
                    </div>
                </div>
            </div>
            <div class="container-question" v-for="q in questions" :key="q.id" :id="'q-'+q.id">
                <div class="h-100">  
                    <div class="question">
                        <p :id="'quest-' + q.id">{{q.question}}</p>
                        <div class="question-adjunt h-auto" v-if="q.questionType == 2">
                            <p>"{{q.description}}"</p>
                        </div>
                        <div class="question-adjunt" v-if="q.questionType == 3">
                            <img :src="q.imgQuizURL" class="img-fluid">
                        </div>
                        <div class="question-adjunt" v-if="q.questionType == 4">
                            <vue-vimeo-player v-if="q.videoFile != ''" ref="player" :video-id="q.videoFile" :options='optionsVimeo'/>
                        </div>
                    </div>
                    <div class="options">
                        <div class="option" v-for="a in q.optionsAnswers" :key="a.id" :id="'a-'+a.id" @click="selectOption(a.id, a.correct, q.points, q.id)">
                            <p>{{a.answer}}</p>
                        </div>
                    </div>
                    <div class="mt-5 pb-5">
                        <button class="btn btn-outline-primary btn-sm" @click="nextQuestion(q.id)">Siguiente</button>
                    </div>
                </div>
            </div>
            <div class="container-question finish-quiz d-flex justify-content-center align-items-center" id="finish">
                <div class="h-100">
                    <h3 class="mb-2 mt-5">{{titleClass}}</h3>
                    <img v-show="quizOk" src="@/assets/imgs/happy.webp" class="img-fluid w-25 quiz-face-finish" alt="">
                    <img v-show="!quizOk" src="@/assets/imgs/sad.webp" class="img-fluid w-25 quiz-face-finish" alt="">
                    <p v-if="!quizOk">No has aprobado el cuestionario</p>
                    <p v-if="quizOk">¡Felicitaciones! </p>
                    <p v-if="quizOk">¡Has completado el cuestionario con éxito!</p>

                    <p v-if="!quizOk && okPoints != '0'">Lamentablemente no has podido completar con éxito el cuestionario.</p>
                    <p v-if="!quizOk && okPoints == '0'">Ha Finalizado el tiempo que tenías para realizarlo.</p>

                    <p v-if="quizOk && okPoints == '0'">Has respondido correctamente {{totalQuestionsOk}} preguntas.</p>
                    <p v-if="quizOk && okPoints != '0'">Has obtenido un total de {{userPoints}} puntos.</p>
                    <div v-for="q in questAnswers" :key="q.id" >
                        <p class="mb-1"><i class="las la-check text-success" v-if="q.correct"></i><i class="las la-times text-danger" v-if="!q.correct"></i> {{q.question}}</p>
                    </div>
                    <div class="mt-5 pb-5">
                        <button v-if="!quizOk" class="btn btn-outline-primary btn-sm" @click="resetQuiz()">Volver a intentar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mx-auto position-fixed bottom-0 end-0 start-0 end-0 p-0 pt-3 pb-3" style="z-index: 11000; width: fit-content; ">
        <div id="toastcontainer" class="w-25">
        </div>
    </div>

</template>
<script>
import axios from "axios";
import { Toast } from 'bootstrap';
import { vueVimeoPlayer } from 'vue-vimeo-player'
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {   
    name: "quiz-module-preview",
    props: ['previewId'], 
    data(){
        return{
            errorText: '',
            toastColor: '',
            toastBg: '',
            toastNumber: 0,
            dataReceiver: '',
            durationQuiz: '',
            timeQuiz: 0,
            okPoints: 0,
            questions: [],
            optionsVimeo: { //opciones de vimeo
				muted: true,
                autoplay: false,
                controls: true,
                color: '4586e6',       
			},
            titleClass: '',
            correctQuestion: false,
            userPoints: 0,
            questionPts: 0,
            quizOk: false,
            totalQuestionsOk: 0,
            counterFinish: '',
            isSelected: false,
            questAnswers: []
        }
    },
    components: {
        vueVimeoPlayer,
        VueCountdown
    },
    mounted: function () {
        this.dataReceiver = localStorage.getItem('token');
        this.findQuizData();
        this.findQuestionData();
    },
    methods:{
        restCountDown(){
            let now = new Date(); 
            let newDate = new Date(); 
            var sum = (newDate.getMinutes() + parseInt(this.timeQuiz));
            newDate.setMinutes(sum);
            this.counterFinish = (newDate - now);  
        },
        onCountdownEnd(){
            this.quizOk = false;
            var elems = document.querySelectorAll(".selected");
            [].forEach.call(elems, function(el) {
                el.classList.remove("selected");
            });
            var elems = document.querySelectorAll(".in");
            [].forEach.call(elems, function(el) {
                el.classList.remove("in");
            });
            var elems = document.querySelectorAll(".hide");
            [].forEach.call(elems, function(el) {
                el.classList.remove("hide");
            }); 
            document.querySelector('#finish').classList.add("in");
            this.counterFinish = '';
           
        },
        resetQuiz(){
            this.questAnswers = [];
            this.isSelected = false;
            this.questionPts = 0;
            this.userPoints = 0;
            this.quizOk = false;
            this.totalQuestionsOk = 0;
            this.correctQuestion = false;
            var elems = document.querySelectorAll(".selected");
            [].forEach.call(elems, function(el) {
                el.classList.remove("selected");
            });
            var elems = document.querySelectorAll(".in");
            [].forEach.call(elems, function(el) {
                el.classList.remove("in");
            });
            var elems = document.querySelectorAll(".hide");
            [].forEach.call(elems, function(el) {
                el.classList.remove("hide");
            }); 
            try{
                document.querySelector('#initial').classList.add("in");    
            }catch(e){
                
            }     
            this.restCountDown();
        },
        startQuiz(){ 
            try{
                document.querySelector('#initial').classList.add("hide"); 
                var el = document.querySelector('#initial').nextSibling;
                document.querySelector('#'+el.nextSibling.id).classList.add("in");
                this.correctQuestion = false;
            }catch(e){
                
            } 
            this.restCountDown();       
        },
        nextQuestion(val){  
            if(this.isSelected == false){
                this.errorText = 'Debes eleggir una opción.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){
                    
                }
                return;

            } 
            var quest = document.querySelector('#quest-'+val).innerHTML;
            if(this.correctQuestion){
                this.userPoints = (this.userPoints + parseInt(this.questionPts));
                this.totalQuestionsOk++;
                this.questAnswers.push({'question' : quest, 'correct' : true});
            }else{
                this.questAnswers.push({'question' : quest, 'correct' : false});
            }
            if(this.userPoints >= this.okPoints){
                this.quizOk = true;
            }
            try{
                document.querySelector('#q-'+val).classList.add("hide"); 
                var el = document.querySelector('#q-'+val).nextSibling;
                document.querySelector('#'+el.id).classList.add("in");
                this.correctQuestion = false;
            }catch(e){
                document.querySelector('#finish').classList.add("in");
                this.counterFinish = '';
            }   
            this.isSelected = false;     
        },
        selectOption(answerId, isCorrect, questionPts){
            this.isSelected = true;
            this.correctQuestion = isCorrect;
            this.questionPts = questionPts;
            var elems = document.querySelectorAll(".selected");
            [].forEach.call(elems, function(el) {
                el.classList.remove("selected");
            });
            try{
                document.querySelector('#a-'+answerId).classList.add("selected"); 
            }catch(e){

            }
        }, 
        async findQuizData(){ 
            this.waiting = true; 
            var payload = {
                action: 'find-quiz-data',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.dataReceiver,
                ref: this.previewId
            }
            await axios.post('/index.php', payload)
            .then(result => {
                if(result.data.status == '200'){ 
                    this.okPoints = parseInt(result.data.okPoints); 
                    this.durationQuiz = result.data.duration;
                    this.timeQuiz = result.data.time;
                }   
            })
            .catch(error => {
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){
                    
                }
                return;
            })
        },  
        async findQuestionData(){ 
            this.waiting = true; 
            var payload = {
                action: 'find-all-quiz-question-data',
                browserCountry: this.$route.params.browserCountry,
                userLang: this.$route.params.userLang,
                urlcode: window.location.host,
                dataReceiver: this.dataReceiver,
                quizRef: this.previewId,   
            }
            await axios.post('/index.php', payload)
            .then(result => {
                this.waiting = false;    
                this.questions = result.data;
                
            })
            .catch(error => {
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){
                    
                }
                return;
            })
        },
        async listQuestionsQuiz(){
            var payload = {
                    action: 'list-user-question-quiz',
                    browserCountry: this.$route.params.browserCountry,
                    userLang: this.$route.params.userLang,
                    urlcode: window.location.host,
                    dataReceiver: this.dataReceiver,
                    ref: this.previewId
                } 
                await axios.post('/index.php', payload)
                .then(result => { 
                    this.questions = result.data;  
                })
                .catch(error => {
                this.errorText = 'Revise su conexión de internet.';
                this.toastBg = 'bg-danger';
                this.toastColor = 'text-dark';
                this.toastNumber++;
                var t = '<div id="t'+ this.toastNumber + '" class="toast ml-auto" role="alert" data-delay="10000" data-autohide="true"><div class="toast-header ' + this.toastBg + ' text-white"><i class="las la-bullhorn"></i><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div><div class="d-flex"><div class="toast-body ' + this.toastColor + '">' + this.errorText + '</div></div></div>';
                try{
                    document.querySelector('#toastcontainer').insertAdjacentHTML("afterend", t);
                    var myAlert =document.getElementById('t' + this.toastNumber);//select id of toast
                    var bsAlert = new Toast(myAlert);//inizialize it
                    bsAlert.show();//show it
                }catch(e){

                }
                return;

            })
        }, 
    }
}
</script>